.main-layout {
  padding-bottom: 50px;
  .main-background {
    //background-color: #f5f5f5;
  }

  .navbar {
    background-color: #ffffff !important;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: rgba(0,0,0,0.35) 0px 1px 2px;
  }

  .contacts-container {
    .contact-group-invite {
      text-align: center;
      margin-bottom: 35px;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .contacts-container {
      margin-top: 56px;
      width: 280px;
      margin-left: calc((100% - 280px) / 2);
      margin-right: calc((100% - 280px) / 2);

      .card-content-container {
        .contact-content {
          padding: 8px;
        }
        .contact-name {
          font-size: 0.8rem;
        }
        .contact-email {
          font-size: 0.6rem;
        }
        .contact-header {
          height: 54px;
          min-width: 44px;
          .contact-header-initials {
            text-align: center;
            font-size: 1rem;
          }
        }
        .contact-actions {
          .material-icons {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .contacts-container {
      margin-top: 56px;
      width: 450px;
      margin-left: calc((100% - 450px) / 2);
      margin-right: calc((100% - 450px) / 2);
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .contacts-container {
      margin-top: 56px;
      width: 500px;
      margin-left: calc((100% - 500px) / 2);
      margin-right: calc((100% - 500px) / 2);
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .contacts-container {
      margin-top: 56px;
      width: 350px;
      margin-left: calc((100% - 350px) / 2);
      margin-right: calc((100% - 350px) / 2);
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .contacts-container {
      margin-top: 56px;
      width: 500px;
      margin-left: calc((100% - 500px) / 2);
      margin-right: calc((100% - 500px) / 2);
    }
  }

  @media (min-width: 960px) and (max-width: 1024px) {
    .contacts-container {
      margin-top: 56px;
      width: 700px;
      margin-left: calc((100% - 700px) / 2);
      margin-right: calc((100% - 700px) / 2);
    }
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    .contacts-container {
      margin-top: 56px;
      width: 700px;
      margin-left: calc((100% - 700px) / 2);
      margin-right: calc((100% - 700px) / 2);
    }
  }

  @media (min-width: 1281px) {
    .contacts-container {
      margin-top: 56px;
      width: 800px;
      margin-left: calc((100% - 800px) / 2);
      margin-right: calc((100% - 800px) / 2);
    }
  }

}
