.navigation-tabs {
  display: flex;
  flex-direction: row;

  div {
    border-bottom: 1px solid #000000;
    cursor: pointer;
    width: 120px;
    text-align: center;

    &:hover {
      color: #ffba6b;
      border-bottom: 1px solid #ffba6b;
    }
  }

  .nav-tab-active {
    color: #ffba6b;
    border-bottom: 1px solid #ffba6b;
  }
}
