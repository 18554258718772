.fill {
  min-height: 100%;
  height: 100%;
}

@media (max-width: 347px) {
  .fill-register {
    min-height: auto;
    height: auto;
  }
}

.root {
  min-height: 100%;
  height: 100%;
}

html, body {
  height: 100%;
  font-family: "Roboto", sans-serif !important;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.text-60 {
  font-size: 60px;
  font-weight: 300;
}

.text-20 {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

.primary-button-color {
  color: #fff;
  background-color: #ffba6b;
  border-color: #ffba6b;

  &:hover {
    color: #fff;
    background-color: #ffba6b;
    border-color: #ffba6b;
  }

  &:focus {
    color: #fff;
    background-color: #ffba6b;
    border-color: #ffba6b;
    box-shadow: 0 0 0 0.25rem rgba(255,186,107, 0.5);
  }
}

.img-circle {
  border-radius: 50%;
}

a, body, button, div, h1, h2, h3, h4, h5, h6, html, html a, input, li, p, span, textarea {
  font-family: "Roboto",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
}