$primary: rgb(256, 156, 107);
.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: fixed;
  width: 65px;
  bottom: 42px; right: 30px;

  &:hover {
    height: 100%;
    .sub-button:nth-child(2) {
      transform: translateY(-65px);
    }
    .sub-button:nth-child(3) {
      transform: translateY(-125px);
    }
    .sub-button:nth-child(4) {
      transform: translateY(-185px);
    }
    .sub-button:nth-child(5) {
      transform: translateY(-260px);
    }
    .sub-button:nth-child(6) {
      transform: translateY(-320px);
    }
  }
  .fab {
    position: sticky;
    height: 65px; width: 65px;
    background-color: $primary;
    border-radius: 50%;
    z-index: 2;
    &::before {
      content: " ";
      position: sticky;
      bottom: 0; right: 0;
      height: 35px; width: 35px;
      background-color: inherit;
      border-radius: 50%;
      z-index: -1;
    }
    .fab-content {
      display: flex;
      align-items: center; justify-content: center;
      height: 100%; width: 100%;
      border-radius: 50%;

      .material-icons {
        color: white;
        font-size: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 25px;
        height: 25px;
      }

    }
  }
  .sub-button {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center; justify-content: center;
    bottom: 10px; right: 10px;
    height: 50px; width: 50px;
    background-color: $primary;
    border-radius: 50%;
    transition: all .3s ease;

    img {
      height: 25px;
      width: 25px;
    }

    &:hover {
      cursor: pointer;
    }
    .material-icons {
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}