.contact-list-label {
  width: 15%;
}

.contact-list-divider {
  width: 75%;
}

.text-end {
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .expand-contacts {
    cursor: pointer;
    margin-left: 5px;
    width: 12px;
    height: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contact-list-label {
    width: 35%;
  }

  .contact-list-divider {
    width: 50%;
  }

  .text-end {
    width: 15%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .contact-list-label {
    width: 35%;
  }

  .contact-list-divider {
    width: 50%;
  }

  .text-end {
    width: 15%;
  }
}