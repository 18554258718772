.button {
  background-color: transparent;
  border: none;
}

.up-arrow {
  width: 25px;
  height: 25px;
  filter: invert(41%) sepia(99%) saturate(1000%) hue-rotate(326deg)
    brightness(116%) contrast(96%); // change color of png. This stands for #fb6669
  position: fixed;
  bottom: 1px;
  right: calc(50%);
}

.down-arrow {
  width: 25px;
  height: 25px;
  filter: invert(41%) sepia(99%) saturate(1000%) hue-rotate(326deg)
    brightness(116%) contrast(96%); // change color of png. This stands for #fb6669
  position: fixed;
  bottom: 41px;
  right: calc(50%);
}

.footer-container {
  display: block;
  background-color: #ff9c6b;
  position: fixed;
  bottom: 60px;
  width: 100%;
}

@media (max-width: 768px) {
  .footer-container {
    display: none;
    background-color: #ff9c6b;
    overflow: auto;
  }

  .up-arrow {
    display: none;
  }

  .down-arrow {
    display: none;
  }
}
