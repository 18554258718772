.dropdown-menu {
  right: 0;

  div {
    cursor: pointer;
  }
}
.dropdown-toggle::after {
  display: none;
}

.color-black {
  color: #6b6b6b;
}

.safariWarning {
  width: 100%;
  padding-left: 50px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #fb6669;
  color: #fff;
}
