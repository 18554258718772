.main-container-register {
  .row {
    padding-right: 0px;
  }
  .main-container-login-col {
    background-color: #f2fbfe;
  }

  .register-wallpaper {
    background-image: url(./../../assets/kriptera-register.png);
    background-size: cover;
  }
}

.register-button {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
}
