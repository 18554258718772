$background-size: 45px;

.file-item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  background-color: rgba(255,255,255,0);
  position: relative;

  .dropdown-menu {
    left: -95px;
  }
  .file-properties {
    font-size: 12px;
    width: 200px;
    padding: 10px;
  }

  .file-action {
    position: absolute;
    cursor: pointer;
    top: 8%;
    right: 48%;
  }

  .file-icon {
    width: $background-size;
    height: $background-size;
    margin-right: 5px;
    margin-bottom: 5px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: $background-size $background-size;
  }

  .file-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .file-name {
      white-space: nowrap;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      font-size: 13px;
    }

    .file-date {
      color: #dbdbdb;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .file-item {
    flex-direction: row;
    justify-content: start;
    .file-action {
      position: absolute;
      top: 13%;
      left: 10%;
    }

    .file-details {
      justify-content: flex-start;
      align-items: start;

      .file-name {
        font-size: 13px;
      }

      .file-date {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .file-item {
    flex-direction: row;
    justify-content: start;
    .file-action {
      position: absolute;
      top: 13%;
      left: 6%;
    }
    .file-details {
      justify-content: flex-start;
      align-items: start;

      .file-name {
        font-size: 13px;
      }

      .file-date {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (min-width: 768px) and (max-width: 1024px) {

}

@media (min-width: 960px) and (max-width: 1024px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 1281px) {

}

.pdf {
  background-image: url('../../assets/files/pdf.png')
}
.docx {
  background-image: url('../../assets/files/doc.png')
}
.doc {
  background-image: url('../../assets/files/doc.png')
}
.xls {
  background-image: url('../../assets/files/xls.png')
}
.xlsx {
  background-image: url('../../assets/files/xls.png')
}
.mp3 {
  background-image: url('../../assets/files/mp3.png')
}
.ogg {
  background-image: url('../../assets/files/mp3.png')
}
.mp4 {
  background-image: url('../../assets/files/mp4.png')
}
.wav {
  background-image: url('../../assets/files/avi.png')
}
.mov {
  background-image: url('../../assets/files/avi.png')
}
.zip {
  background-image: url('../../assets/files/zip.png')
}
.avi {
  background-image: url('../../assets/files/avi.png')
}
.z7 {
  background-image: url('../../assets/files/zip.png')
}
.rar {
  background-image: url('../../assets/files/zip.png')
}
.jpeg {
  background-image: url('../../assets/files/jpg.png')
}
.jpg {
  background-image: url('../../assets/files/jpg.png')
}
.png {
  background-image: url('../../assets/files/png.png')
}
.exe {
  background-image: url('../../assets/files/exe.png')
}
.file {
  background-image: url('../../assets/files/file.png')
}
.file {
  background-image: url('../../assets/files/file.png')
}