.main-container-login {
  .row {
    padding-right: 0px;
  }
  .main-container-login-col {
    background-color: #f2fbfe;
    height: 100%;
  }

  .login-wallpaper {
    background-image: url(./../../assets/login_wallpaper.png);
    background-size: cover;
  }
}
