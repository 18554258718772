.modal-content {
  border-radius: 1em;
  border: none;
}

.modal-header {
  border: none;
  padding-bottom: 2px;
}

.navbar-settings-container {
  .section-title {
    margin-bottom: 20px;
  }

  .navbar-settings-user-info {
    padding-bottom: 12px;
    img {
      max-width: 45px;
      max-height: 45px;
      width: 100%;
      height: 100%;

    }
  }

  .navbar-settings-section {
    padding-top: 10px;
    border-top: 1px solid #dedbdb;
    width: 100%;
  }

  .sub-label {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 8px;
  }

  .active-sub {
      border: 2px solid #65d7c1 !important;
      color: #65d7c1 !important;
  }

  .inactive-sub {
    border: 2px solid #f36648 !important;
    color: #f36648 !important;
  }

  .sub-info {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2em;
    padding: 1px 10px 1px 10px;
    border: 2px solid #b8b8b8;
    color: #b8b8b8;
  }

  .sub-label-second-row {
    margin-right: 2px;
  }

  .sub-info-second-row {
    font-size: 13px;
    display: flex;
    align-items: center;
    border-radius: 2em;
    padding: 1px 8px 1px 8px;
    border: 2px solid #65d7c1;
    color: #65d7c1;
  }

  //Google Button Styling
  .abcRioButtonBlue, .abcRioButtonBlue:hover {
    background-color: #ffba6b;
  }

  .abcRioButton {
    border-radius: 1em;
  }

  .abcRioButtonIcon {
    border-bottom-left-radius: 1em !important;
    border-top-left-radius: 1em !important;
  }

  .abcRioButton:hover {
    box-shadow: 0 0 3px 3px rgba(213, 156, 90, 0.5);
  }
  //End of Google Button Styling
}
