.tab-container {
  height: 55px;

  .tab-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    font-size: 12px;
    height: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }

  .selected {
    border-bottom: 2px solid #ffba6b;
    color: rgba(0, 0, 0, 1);
  }
  
  .safariWarning {
    height: 50px;
    background-color: #fb6669;
  }
}