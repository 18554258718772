.contact-container {
  border-radius: 8px;
  box-shadow: rgba(0,0,0,0.35) 0px 1px 10px;
  cursor: pointer;
  
  .contact-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.56);
    z-index: 10;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .delete-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .letter {
    max-width: 60px;
    max-height: 75px;
    height: 100%;
    width: 100%;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    text-align: center;
    padding: 10px;
    background-color: #fb6669;
    color: white;
    font-size: 30px;
  }

  .contact-full-name {
    font-size: 15px;
    color: #000000;
    max-width: 150px;
    width: 100%;
  }

  .contact-email {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
    max-width: 150px;
    width: 100%;
  }

  .delete-contact {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    max-width: 55px;
    max-height: 75px;
    height: 100%;
    width: 100%;
    align-self: flex-end;
  }
}

@media (min-width: 480px) {
  .letter {
    max-width: 75px;
    max-height: 75px;
    height: 100%;
    width: 100%;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    text-align: center;
    padding: 10px;
    background-color: #fb6669;
    color: white;
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contact-full-name {
    font-size: 12px !important;

  }

  .contact-email {
    font-size: 10px !important;
  }

  .letter {
    max-width: 50px !important;
    max-height: 50px !important;
    font-size: 20px !important;
  }

  .delete-contact {
    font-size: 10px !important;
  }
}
