$primary: rgb(256, 156, 107);

.bottom-bar-custom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff !important;
  transition: all 0.2s ease-in-out 0s;
  box-shadow: rgba(0,0,0,0.35) 0px -1px 2px;
  height: 35px;
  padding: 0;


  .selected {
    background-color: #ffba6b;
    color: #ffffff;
  }

  .tab {
    display: flex;
    text-align: center;
    padding: 0;
    padding-top: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 49.95%;
    font-size: 12px;
  }

  .add-button {
     width: 0.1%;
    .add-contact-container {
      position: relative;
      width: 0px;
      padding: 0;

      .add-contact {
        background-color: $primary;
        width: 45px;
        height: 45px;
        position: absolute;
        transform: rotate(45deg);
        top: -52px;
        left: -23px;
        box-shadow: rgba(245,245,245, 1) 2px 2px 0, rgba(0,0,0, 0.35) 3px 3px 0;
        border-radius: 5px;

        .plus-sign {
          height: 100% !important;
          width: 100% !important;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          transform: rotate(-45deg);
          font-size: 20px;
          color: #ffffff;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &:hover {
        .sub-button:nth-child(1) {
          transform: rotate(45deg) translate(-46px, -45px);
        }
        .sub-button:nth-child(2) {
          transform:  rotate(45deg) translate(-85px, -85px);
        }
        .sub-button:nth-child(3) {
         transform: rotate(45deg) translate(-124px, -124px);
        }
        //.sub-button:nth-child(5) {
        //  transform: translateY(-260px);
        //}
        //.sub-button:nth-child(6) {
        //  transform: translateY(-320px);
        //}
      }

      .sub-button {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center; justify-content: center;
        bottom: 9px; right: -18px;
        height: 35px; width: 35px;
        background-color: $primary;
        border-radius: 5px;
        transition: all .3s ease;
        transform: rotate(45deg);

        img {
          height: 20px;
          width: 20px;
          transform: rotate(-45deg);
        }

        &:hover {
          cursor: pointer;
        }
        .material-icons {
          color: white;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

}